import { GetBuildings } from '@design-system/types/buildings/buildingsTypes'

export const getBuildingDisplayAdress = (building: GetBuildings) => {
    const { address } = building

    const zipCode = address?.data?.zipCode
    const street = address?.data?.street
    const city = address?.data?.city

    if (street) return `${street}`

    if (zipCode && city) return `${zipCode} ${city}`

    return `${zipCode}`
}
