import { BUILDING_CALLS, BuildingCallsBasicKey, SubmitBuildingPayload } from '@api/buildings/buildingsCalls'
import { GetBuildingCategories, GetBuildingMeasures, GetBuildingMeasuresFaq } from '@api/buildings/buildingsTypes'
import { useBuildingContext } from '@common/context/buildingContext'
import { QueryKey } from '@tanstack/react-query'
import { useEnterApi, useEnterMutation, useEnterQuery } from '@design-system/api/apiContext'
import { EnterQueryOptions } from '@design-system/api/apiFactory'
import {
    BuildingMeasuresValues,
    GetBuildings,
    OpportunityStage,
    SubsidyServiceName,
    SurveyName,
} from '@design-system/types/buildings/buildingsTypes'
import { getIsAtOrPassedOpportunityStage } from '@design-system/utils/buildings/buildingsHelpers'

export const useGetAllBuildings = ({ enabled = true }) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_ALL_BUILDINGS]

    return useEnterQuery<GetBuildings[]>(getKey(), () => call(enterApi), {
        enabled,
        retry: 5,
    })
}

export const useGetBuildingByUuid = ({
    buildingUuid,
    enabled,
    ...rest
}: EnterQueryOptions<GetBuildings, QueryKey> & { buildingUuid: string }) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_BUILDING]

    return useEnterQuery<GetBuildings>(getKey(buildingUuid), () => call(enterApi, { buildingUuid }), {
        enabled: Boolean(buildingUuid && enabled),
        ...rest,
    })
}

export const useGetBuildingMeasures = ({
    buildingUuid,
    isEnabled = true,
    retry = false,
}: {
    buildingUuid: string
    isEnabled?: boolean
    retry?: boolean | number
}) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_BUILDING_MEASURES]

    return useEnterQuery<GetBuildingMeasures>(getKey(buildingUuid), () => call(enterApi, buildingUuid), {
        enabled: !!buildingUuid && isEnabled,
        retry,
    })
}

export const useGetBuildingCategories = (buildingUuid: string, surveyName: `${SurveyName}`) => {
    const enterApi = useEnterApi()
    const { buildingData: building } = useBuildingContext()
    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_BUILDING_CATEGORIES]

    return useEnterQuery<GetBuildingCategories>(
        getKey(buildingUuid, surveyName),
        () => call(enterApi, buildingUuid, surveyName),
        {
            enabled: !!buildingUuid,
            select: (data) => {
                if (surveyName === SurveyName.appointment || surveyName === SurveyName.main) return data

                // const sliInQuestion = getLatestSubsidiesServiceLineItem(building)
                const sliInQuestion = building?.serviceLineItems?.data?.find((sli) => {
                    return sli.subsidiesService?.data?.subType === SubsidyServiceName[surveyName]
                })

                const userIsAtOrPastProjectStage = getIsAtOrPassedOpportunityStage(
                    sliInQuestion?.currentStage,
                    OpportunityStage.Project
                )

                if (!userIsAtOrPastProjectStage) {
                    const filteredData = data.categories?.filter((category) => category.formUuid.indexOf('kf3-') < 0)

                    return {
                        ...data,
                        categories: filteredData,
                    }
                }

                return data
            },
        }
    )
}

type UseGetBuildingMeasuresFaq = {
    buildingUuid: string
    rawSubsidizableMeasureComponent: BuildingMeasuresValues | undefined
}

export const useGetBuildingMeasuresFaq = ({
    buildingUuid,
    rawSubsidizableMeasureComponent,
}: UseGetBuildingMeasuresFaq) => {
    const enterApi = useEnterApi()

    // We can call the api with an empty string, because the enabled flag is set in react-query.
    const subsidizableMeasureComponent = rawSubsidizableMeasureComponent || ('' as BuildingMeasuresValues)

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_BUILDING_MEASURES_FAQ]

    return useEnterQuery<GetBuildingMeasuresFaq>(
        getKey(buildingUuid, subsidizableMeasureComponent),
        () => call(enterApi, buildingUuid, subsidizableMeasureComponent),
        { enabled: Boolean(buildingUuid && subsidizableMeasureComponent) }
    )
}

// MIGRATION: can be removed when the survey is migrated
export const usePostBuilding = (buildingUuid: string) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.mutations[BuildingCallsBasicKey.POST_BUILDING]

    return useEnterMutation<undefined, SubmitBuildingPayload>(
        getKey(buildingUuid),
        (payload) => call(enterApi, payload, buildingUuid),
        {}
    )
}

// MIGRATION: can be removed when the survey is migrated
export const usePostBuildingScoreStatus = (buildingUuid: string) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.mutations[BuildingCallsBasicKey.POST_BUILDING_SCORE_STATUS]

    return useEnterMutation<undefined, SubmitBuildingPayload>(
        getKey(buildingUuid),
        (payload) => call(enterApi, payload, buildingUuid),
        {}
    )
}
